export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"name":"lang","content":"az"}],"link":[{"href":"/css/all.min.css","rel":"stylesheet"},{"href":"/css/animate.min.css","rel":"stylesheet"},{"href":"/css/icofont.min.css","rel":"stylesheet"},{"href":"/css/bootstrap.min.css","rel":"stylesheet"},{"href":"/css/swiper.css","rel":"stylesheet"},{"href":"/css/login-modal.css","rel":"stylesheet"},{"href":"/css/owl.theme.default.min.css","rel":"stylesheet"},{"href":"/css/owl.carousel.min.css","rel":"stylesheet"},{"href":"/css/style.css","rel":"stylesheet","id":"style"}],"style":[],"script":[{"src":"/js/Jquery.js"},{"src":"/js/swiper.js"},{"src":"/js/popper.js"},{"src":"/js/bootstrap.min.js"},{"src":"/js/login-modal.js","async":true},{"src":"/js/owl.carousel.min.js"},{"src":"/js/core.js","async":true},{"src":"//code.jivosite.com/widget/bzk1oo6QMq","async":true}],"noscript":[],"htmlAttrs":{"lang":"az"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = false

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false